import React from 'react';
import { Layout, Menu, theme } from 'antd';
import {
  // Outlet,
  // NavLink,
  // useLoaderData,
  // Form,
  // redirect,
  Link,
  // createBrowserRouter
} from "react-router-dom";

import { LaptopOutlined, NotificationOutlined, UserOutlined } from '@ant-design/icons';
const { Sider } = Layout;

const menu = [
  {
    label: "Parts",
    icon: UserOutlined, // React.createElement(UserOutlined),
    subMenu: [
      {
        label: (<Link to={`/add-qc-checks`}> Add QC Checks  </Link>),
      },
      {
        label: (<Link to={`/add-part`}> Add Parts  </Link>),
      }
    ]
  }
];
// console.log("")
const items2 = menu.map((item, index) => {
  const key = String(index + 1);
  return {
    key: `sub${key}`,
    icon: React.createElement(item.icon),
    label: item.label,
    children: item.subMenu.map((sMenu, j) => {
      const subKey = index * 4 + j + 1;
      return {
        key: subKey,
        label: (sMenu.label),
        
      };
    }),
  };
});

const AppSidebar = ()=>{
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();
    return (
        <Sider
          width={200}
          style={{
            background: colorBgContainer,
          }}
        >
          <Menu
            mode="inline"
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            style={{
              height: '100%',
              borderRight: 0,
            }}
            items={items2}
          />
        </Sider>
    );
}

export { AppSidebar }
