import App from "../App.js"
import {AddPartForm} from "./part/addPart"
import {AddQcChecks} from "./qcCheck/addQcCheck"
import {Provider} from "react-redux"
// import store from "../rootRedux/appStore";

const routers = [
    {
      path: "/",
      // element: <Provider store={store}><App/></Provider>,
      element: <App />,
      children: [
        {
          path: "add-qc-checks",
          element: <AddQcChecks />
        },
        {
          path: "add-part",
          element: <AddPartForm />
        }
      ]
    },
    {
      path: "/ntn-parts",
      element: <AddPartForm />,
    },
];

export {routers}
