import { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Cascader,
  Checkbox,
  ColorPicker,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Rate,
  Select,
  Slider,
  Switch,
  TreeSelect,
  Upload,
  Divider
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addQcCheck, removeQcCheck, addQcCheckAsync } from './redux/qcCheckSlice';


const { RangePicker } = DatePicker;
const { TextArea } = Input;
const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};
const AddQcChecks = () => {
  // const [componentDisabled, setComponentDisabled] = useState(true);
  const count = useSelector(state=>state.qcCheck.count)
  const dispatch = useDispatch();
  const onFinish = (values) => {
    console.log("+++++++++++++++++++", values);
    dispatch(addQcCheckAsync(values));
    // dispatch(addQcCheck())
  }
  const [form] = Form.useForm();
  return (
    <>
      <Divider
        style={{
          borderColor: '#7cb305',
        }}
      >
        Add QC Checks
      </Divider>
      
      <Form
        form={form}
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 14,
        }}
        layout="horizontal"
        style={{
          maxWidth: 600,
        }}
        onFinish={onFinish}
      > 
        <Form.Item label="Name" name="name">
          <Input placeholder='QC Check Name' />
        </Form.Item>
        <Form.Item label="Description" name="description" >
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item label="Upload" valuePropName="fileList" getValueFromEvent={normFile}>
          <Upload action="/upload.do" listType="picture-card">
            <button
              style={{
                border: 0,
                background: 'none',
              }}
              type="button"
            >
              <PlusOutlined />
              <div
                style={{
                  marginTop: 8,
                }}
              >
                Upload
              </div>
            </button>
          </Upload>
        </Form.Item>
        <Form.Item>
        <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
        {/* <Form.Item>
          <Button onClick={()=>dispatch(addQcCheck())} label="+"></Button>
          <div>{count}</div>
          <Button onClick={()=>dispatch(removeQcCheck())} label="-"></Button>
        </Form.Item> */}
      </Form>
    </>
  );
};

export { AddQcChecks }
