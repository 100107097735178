import { createSlice } from "@reduxjs/toolkit";
import axios from "axios"


export const qcCheckSlice = createSlice({
    name: "qcCheck",
    initialState:{
        qcChecks: [],
        addQcCheck: {},
        count: 11
    },
    reducers: {
        addQcCheck: (state, payload)=>{
            state.qcChecks.push(payload)
        }, 
        removeQcCheck: state => {
            state.count -=1;
        }
    }
});

export const addQcCheckAsync = (qcCheckData) => async(dispatch) =>{
    console.log("async addQcCheckAsync", qcCheckData)
    try {
        const response = await axios({
            url: "http://127.0.0.1:8080/qcCheck/addQcCheck", // "http://127.0.0.1:8080/part/addQcCheck",
            method: "POST",
            data: qcCheckData
        })
    } catch (error) {
        console.error(error)
    }
    // dispatch(addQcCheck(qcCheckData))
} 

export const {addQcCheck, removeQcCheck} = qcCheckSlice.actions;

export default qcCheckSlice.reducer;
 