import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from "react-redux"
import './index.css';
import reportWebVitals from './reportWebVitals';
import {routers} from './component/router'
import store from "./rootRedux/appStore";
// import App from "./App.js"
// import {AddPartForm} from "./component/part/addPart"
// import {AddQcChecks} from "./component/qcCheck/addQcCheck"
import {  
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter(routers);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
